<template>
  <el-dialog
    :title="'Add Products'"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <div>
      <p class="mb-2">
        Choose the products you want in this curation by ticking their
        checkboxes.
      </p>

      <el-input
        class=""
        v-model="searchQuery"
        type="text"
        :prefix-icon="'el-icon-search'"
        placeholder="Search"
        @input="handleSearchInput"
      ></el-input>

      <!-- <pre>{{ selectedCuration }}</pre> -->

      <div v-if="selectedCuration.length" class="mt-2">
        <p>SELECTED PRODUCTS</p>

        <ul class="curation-list">
          <li
            v-for="item in selectedCuration"
            :key="item.uuid"
            class="text-primart font-md"
          >
            <div class="is-flex">
              <img
                v-if="item.image_url || item.image_url === ''"
                class="rounded"
                :src="getThumbnailFromCloudinary(item.image_url)"
                width="40px"
                height="40px"
                alt="Image"
              />
              <div class="ml-2">
                {{ item.name ? item.name : item.product_name }} <br />
                {{ formatPrice(item.price) }}
              </div>
            </div>

            <div>
              <el-checkbox
                v-model="item.checked"
                @change="selectCuration(item)"
              />
            </div>
          </li>
        </ul>
      </div>

      <div v-if="selectedCuration.length" class="mt-5">
        <p>ALL PRODUCTS</p>
      </div>
      <ul class="curation-list">
        <li
          v-for="(item, index) in computedCurations"
          :key="item.uuid + index"
          class="text-primart font-md"
          @click="selectCuration(item)"
        >
          <div class="is-flex">
            <img
              v-if="item.image_url || item.image_url === ''"
              class="rounded"
              :src="getThumbnailFromCloudinary(item.image_url)"
              width="40px"
              height="40px"
              alt="Image"
            />
            <div class="ml-2">
              {{ item.name }} <br />
              {{ formatPrice(item.price) }}
            </div>
          </div>

          <div><el-checkbox v-model="item.checked" /></div>
        </li>
      </ul>
    </div>

    <div v-if="hasMore" class="mt-2">
      <el-button type="plain" @click="loadMore" class="w-100"
        >See more</el-button
      >
    </div>

    <span
      slot="footer"
      class="dialog-footer is-flex is-justify-between is-justify-end is-align-center"
    >
      <el-button type="plain" @click="closeEvent"> Cancel</el-button>
      <el-button
        type="primary"
        :loading="loading"
        v-if="!selectedCuration.length"
        >Save category</el-button
      >
      <el-button type="primary" :loading="loading" @click="addProduct" v-else
        >Add products</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import curation from "../../requests/curations/curation";
export default {
  name: "AddProducts",
  components: {},
  props: {
    show: Boolean,
    filterProduct: {
      type: Array,
      default() {
        return [];
      },
    },
    businessType: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["update:show", "shower"],
  data() {
    return {
      loading: false,
      checked: "",
      searchQuery: "",
      curations: [],
      selectedCuration: [],
      logPagination: { page: 1, total: 1, perPage: 15 },
      timer: null
    };
  },

  watch: {
    "logPagination.page"(oldV, newV) {
      if (oldV != newV) {
        this.getCurations();
      }
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    hasMore() {
      return this.page * this.perPage < this.total;
    },
    page() {
      return this.logPagination.page;
    },
    total() {
      return this.logPagination.total;
    },
    perPage() {
      return this.logPagination.perPage;
    },
    computedCurations() {
      return this.curations.filter(
        (item) =>
          !this.selectedCuration.some((selItem) => selItem.uuid === item.uuid),
      );
    },
    canAddCuration() {
      return !Object.keys(this.selectedCuration ?? []).length;
    },
    curationId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getCurations();
    // this.initializeSelectedCurationFromFilterProduct();
  },

  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.checked = "";
      this.searchQuery = "";
    },

    handleSearchInput(query) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.searchQuery = query;
        this.curations = [];
        this.logPagination.page = 1;
        this.getCurations();
      }, 2000);
    },

    loadMore() {
      return this.logPagination.page++;
    },

    initializeSelectedCurationFromFilterProduct() {
      this.selectedCuration = this.filterProduct;
      this.selectedCuration.forEach((product) => (product.checked = true));
    },

    getCurations() {
      this.loading = true;
      let business_type_id = this.businessType.map(
        (businessType) => businessType.id,
      );
      let params = `filters[business_type_ids]=[${business_type_id}]&use_lite=0&search=${this.searchQuery}`;
      curation
        .products(this.logPagination.page, params)
        .then((response) => {
          this.logPagination.total = response.data.data.total;
          this.logPagination.perPage = response.data.data.per_page;
          this.curations = [...this.curations, ...response.data.data.data];
          this.curations.forEach((item) => {
            if (
              this.filterProduct.some((product) => product.uuid === item.uuid)
            ) {
              item.checked = true;
            }
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    selectCuration(item) {
      const exists = this.selectedCuration.some(
        (curation) => curation.uuid === item.uuid,
      );
      if (exists) {
        this.selectedCuration = this.selectedCuration.filter(
          (curation) => curation.uuid !== item.uuid,
        );
        item.checked = false; // mark as unchecked
      } else {
        item.checked = true; // mark as checked
        this.selectedCuration = [...this.selectedCuration, item];
      }
    },

    addProduct() {
      this.loading = true;
      let payload = {
        product_ids: this.selectedCuration.map((item) =>
          item.uuid ? item.uuid : item.product_uuid,
        ),
      };
      // const curationsToAdd = this.selectedCuration.filter(
      //   (item) =>
      //     !this.filterProduct.some(
      //       (filteredItem) => filteredItem.uuid === item.uuid,
      //     ),
      // );

      // let payload = {
      //   product_ids: curationsToAdd.map((item) => item.uuid),
      // };

      curation
        .attach(this.curationId, payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeEvent();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.curation-list {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    padding: 1rem 0;
    border-bottom: 1px solid #f0f4f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.create-curation--link {
  cursor: pointer;
}

.rounded {
  border-radius: 8px;
}
</style>
